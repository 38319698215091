import React from 'react'

function Home() {
    return (
        <div>
            <h1>nathanmlee</h1>
            <p><em>Coming Soon</em></p>
        </div>
    )
}

export default Home